import { gql } from '@apollo/client';

export const USER_INFO = gql`
  fragment userInfo on User {
    id
    firstName
    lastName
    fullName
    email
    username
    countryId
    superAdmin
    shareholder
    featured
    featuredMarketplace
    typeOfAccount
    role
    phoneNumber
    createdAt
    dailyReminder
    beforeActivityReminder
    timezone
    hearAboutUs
    flycrewPlanDetails
    totalCredits
    fullNameFeatured
    missionFeatured
  }
`;

export const SALE_TRANSFER_INFO = gql`
  fragment saleTransferInfo on SaleTransfer {
    id
    currencyAccountValid
    invoicePdf {
      id
      pdf
      pdfUrl
    }
    invoiceToken
    invoiceType
    openfacturaStatus
    leader {
      id
      email
      fullName
      username
      hasOpenfacturaIntegration
      typeOfAccount
      clpAccount {
        id
        category
      }
      usdAccount {
        id
      }
    }
    salePaysheet {
      id
      state
    }
    amount
    fee
    feeWithoutIva
    feePercentage
    gatewayFees
    pendingFinal
    currencyId
    createdAt
    transferredAt
    extrasAmount
  }
`;

export const CLP_ACCOUNT = gql`
  fragment clpAccount on ClpAccount {
    id
    accountNumber
    accountType
    accountRut
    address
    bank
    city
    email
    fullName
    giro
    phone
    razonSocial
    rut
    category
    updatedAt
  }
`;

export const MXN_ACCOUNT = gql`
  fragment mxnAccount on MxnAccount {
    id
    rfc
    client
    address
    city
    phone
    email
    bank
    accountType
    accountNumber
    clabe
    accountName
    accountRfc
    updatedAt
    accountEmail
  }
`;

export const CATEGORY_BENEFIT_INFO = gql`
  fragment categoryBenefitInfo on CategoryBenefit {
    id
    name
    slug
  }
`;
